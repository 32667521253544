@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #021042;
}

.bg-image{
    background-image: url('./Assets/homeImmo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 650px;
}


.bg-image-gradient{
    background: #02104298;
    height: 650px;
}

h1 {
    color: #043F99;
    text-transform: uppercase;
     font-weight: 700;
  }

  h2 {
    color: #021042e8;
    text-transform: uppercase;
     font-weight: 700;
  }

  .bg-partenaire{
    background-image: url(./Assets/partenaire.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 500px;
    max-height:100%;
  }

  .survol-img .survol-text{
    display: none;
    background: linear-gradient(180deg, #ee78247c, #dcded1c5 );
  }

  .survol-img:hover .survol-text{
    display: block;
  }

  @media screen and (max-width:1024px) {
    .bg-image-gradient{
    background: #02104298;
      height: 100%;
      min-height: 650px;
      padding-bottom: 20px;
  }
  }

  @media (prefers-reduced-motion: no-preference){
    .Animation-option{
      animation: reveal linear both ;
      animation-timeline: view(); 
      animation-range: 25vh 75vh
    }
  }
  
  @keyframes reveal {
    from {
     transform: scale(0.9);
      opacity: 0.5;
    }
   
  }

  .glow {
    display: inline-block;
      background: linear-gradient(to left, #EE7924, #fff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    
    
  }
  
  